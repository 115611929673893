import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Stack, TextField, ITextField } from "@fluentui/react";
import { SendRegular, Mic28Regular, Mic28Filled } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import soundWave from "../../assets/images/sound-wave.gif";
import { useAppContext } from "../../components/ContextAndProvider/Context";
interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  voicedisabled?: boolean;
}

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend,
  voicedisabled,
}: Props) => {
  const { isFocusTextField, setIsFocusTextField, isMobile } = useAppContext();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [question, setQuestion] = useState<string>("");
  const [isRecording, setIsRecording] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [recordedAudioBlob, setRecordedAudioBlob] = useState<Blob | null>(null);
  let recordingTimer: any;
  const recordingTimeout = 10000;
  const focusInput = () => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  };
  useEffect(() => {
    if (isFocusTextField) {
      focusInput();
      setIsFocusTextField(false);
    }
  }, [isFocusTextField]);
  const startRecording = async () => {
    try {
      setRecordedAudioBlob(null);
      const stream = await navigator?.mediaDevices.getUserMedia({
        audio: true,
      });
      setMediaStream(stream);

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks: Blob[] = [];
      mediaRecorder.ondataavailable = (event) => {
        if (event?.data?.size > 0) {
          chunks?.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "audio/wav" });
        setRecordedAudioBlob(audioBlob);
        if (audioBlob) {
          // Prepare the audio data for sending to the API

          try {
            const apiUrl =
              "https://cog-w6smbhrpyeemq.openai.azure.com/openai/deployments/gptWhisper/audio/translations";
            const apiKey = "74bd6bd42b574b968b20df1408cfa07f";
            const apiVersion = "2023-09-01-preview";

            const headers = {
              "api-key": apiKey,
            };

            const params = new URLSearchParams({
              "api-version": apiVersion,
            });

            const formData = new FormData();
            formData.append("file", audioBlob, "recorded-audio.wav");

            fetch(`${apiUrl}?${params?.toString()}`, {
              method: "POST",
              headers: headers,
              body: formData,
            })
              ?.then((response) => response?.json())
              ?.then((data) => {
                console.log(data);
                if (data?.text) {
                  onSend(data?.text);
                }
              })
              ?.catch((error) => {
                console.error("Error:", error);
              });
          } catch (error) {
            // Handle network or other errors
            console.error("Error:", error);
          }
        }
      };

      mediaRecorder?.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorderRef?.current) {
      mediaRecorderRef?.current?.stop();
    }
  };
  const sendQuestion = () => {
    if (disabled || !question?.trim() || voicedisabled) {
      return;
    }

    onSend(question);

    if (clearOnSend) {
      setQuestion("");
    }
  };
  const getAudioInput = () => {
    if (!isRecording) {
      setIsRecording(true);
      startRecording();
      if (recordingTimer) {
        clearTimeout(recordingTimer); // Clear any existing timer
      }
      recordingTimer = setTimeout(stopAudioInput, recordingTimeout);
    }
  };
  const stopAudioInput = () => {
    if (recordingTimer) {
      clearTimeout(recordingTimer); // Clear any existing timer
    }
    setIsRecording(false);
    stopRecording();
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev?.key === "Enter" && !ev?.shiftKey) {
      ev?.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setQuestion(newValue || "");
  };

  const sendQuestionDisabled = disabled || !question.trim() || voicedisabled;

  return (
    <Stack
      horizontal
      className={`${
        !isMobile
          ? styles?.questionInputContainer
          : styles?.mquestionInputContainer
      }`}
    >
      <div className={`${styles?.IpCnt} IpCnt`}>
        <TextField
          className={styles?.questionInputTextArea}
          placeholder={placeholder}
          resizable={false}
          borderless
          value={question}
          onChange={onQuestionChange}
          onKeyDown={onEnterPress}
          maxLength={300}
          componentRef={inputRef as React.RefObject<ITextField>}
          styles={{
            root: { width: "95%" },
            fieldGroup: { height: 45, padding: 10 },
          }}
        />
        {isRecording && (
          <>
            <img
              className={styles?.soundwave}
              src={soundWave}
              alt="Sound Wave"
            />
            <img
              className={styles?.soundwaveright}
              src={soundWave}
              alt="Sound Wave"
            />
          </>
        )}
        {isRecording ? (
          <span
            className={styles?.micOnSpriteIcon}
            onClick={stopAudioInput}
          ></span>
        ) : voicedisabled ? (
          <span className={styles?.micOffSpriteIconDisabled}></span>
        ) : (
          <span
            className={styles?.micOffSpriteIcon}
            onClick={getAudioInput}
          ></span>
        )}
      </div>
      <div
        className={`${
          !isMobile
            ? styles?.questionInputSendButtonContainer
            : styles?.mquestionInputSendButtonContainer
        }`}
        role="button"
        tabIndex={0}
        aria-label="Ask question button"
        onClick={sendQuestion}
        onKeyDown={(e) =>
          e.key === "Enter" || e.key === " " ? sendQuestion() : null
        }
      >
        {sendQuestionDisabled ? (
          // <SendRegular className={styles.questionInputSendButtonDisabled} />
          <span
            className={`${
              !isMobile
                ? styles?.questionInputSendButtonDisabled
                : styles?.mquestionInputSendButtonDisabled
            }`}
          ></span>
        ) : (
          // <img src={Send} className={styles.questionInputSendButton} />
          <span className={styles?.questionInputSendButton}></span>
        )}
      </div>
    </Stack>
  );
};
