import React, { useEffect, useState } from "react";
import {
  CNavItem,
  CNavLink,
  CContainer,
  CHeader,
  CHeaderNav,
  CAvatar,
  CPopover,
  CRow,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CFormCheck,
  CFormSelect,
  CInputGroup,
  CFormRange,
  CInputGroupText,
  CButton,
  CModal,
  CModalHeader,
  CCardTitle,
  CModalBody,
  CCard,
  CCardBody,
  CCardText,
  CModalFooter,
  CTooltip,
  CFormSwitch
} from "@coreui/react";
import {
  cilAppsSettings,
  cilChevronBottom,
  cilChevronCircleDownAlt,
  cilInfo,
  cilReload,
  cilSettings
} from "@coreui/icons";
// import AppBreadcrumb from "./AppBreadcrumb";
import appLogo from "../assets/brand/hm-logo.png";
import { BsGear } from "react-icons/bs";

import { useMsal } from "@azure/msal-react";
import "../assets/scss/_headertitle.scss";
// import { getAppInsights } from "../services/TelemetryService";
import { getInitials } from "./CommonUtils/CommonUtils";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig
} from "../environments/env";
import { useAppContext } from "./ContextAndProvider/Context";
import CIcon from "@coreui/icons-react";
import Switch from "react-switch";
import dark from "../assets/images/dark.svg";
import light from "../assets/images/light.svg";
import debug from "../assets/images/debug.svg";
import user from "../assets/images/user.svg";
import azure from "../assets/images/azure.svg";
import openai from "../assets/images/openai.svg";
import { Drawer, Tabs, Input, Button, Row, Col, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { fetchGuidelines, updateGuidelines } from "../api";
import { GetRoles } from "../services/AuthUser";
import { IoClose } from "react-icons/io5";
const { TabPane } = Tabs;
interface AppHeaderProps {
  userDetails: any[];
  toggleClick: (isDarkTheme: boolean) => void;
  showBamHeader: boolean;
}
interface Guidelines {
  GeneralInstructionsTemplate?: string;
  RephraseInstructionsTemplate?: string;
  IntentClassificationTemplate?: string;
  PromptSchemaCompressionTemplate?: string;
  TexttoSQLTemplate?: string;
  ResponseInstructionsTemplate?: string;
  tbl_asq_forecast?: string;
  tbl_planning_information?: string;
  tbl_pos_sales_receipts?: string;
  tbl_stock_store?: string;
  tbl_store_sales_forecast?: string;
}
const AppHeader: React.FC<AppHeaderProps> = ({
  userDetails,
  toggleClick,
  showBamHeader
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [configurationVisible, setConfigurationVisible] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState<{
    [key: string]: number;
  }>({});

  const toggleModal = (e?: React.MouseEvent | React.KeyboardEvent) => {
    if (e) {
      e.stopPropagation(); // Prevent event bubbling
    }
    setModalVisible((prevVisible) => !prevVisible);
  };
  const handleFocus = (key: any) => {
    setTextAreaHeight({
      ...textAreaHeight,
      [key]: 300 // Expand height when clicked
    });
  };

  const handleBlur = (key: any) => {
    setTextAreaHeight({
      ...textAreaHeight,
      [key]: guidelines && guidelines[key] ? 200 : 120 // Min height of 200px if there is data, else reset
    });
  };
  const handleResetGuideLines = async () => {
    await fetchData();
  };
  const handleDeploy = async () => {
    Modal.confirm({
      title: "Confirm Deploy",
      content: "Are you sure you want to deploy the updated guidelines?",
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: async () => {
        console.log("On Deploy");
        try {
          const response = await updateGuidelines(guidelines);
          if (response.ok) {
            Modal.success({
              content: "Guidelines updated successfully!"
            });
          } else {
            Modal.error({
              content: "Failed to update guidelines."
            });
          }
        } catch (error) {
          Modal.error({
            content: "An error occurred while updating guidelines."
          });
        }
      }
    });
  };
  const { instance } = useMsal();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  // let appInsights

  // useEffect(() => {
  //   appInsights = getAppInsights()
  // }, [])

  const handleLogout = (logoutType: string) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/"
      });
      sessionStorage.clear();
      localStorage.clear();
      instance.logoutRedirect();
    }
  };
  const {
    modelValue,
    setModelValue,
    maxResponse,
    setMaxResponse,
    temperature,
    setTemperature,
    topP,
    setTopP,
    pastMessage,
    setPastMessage,
    isDarkTheme,
    setIsDarkTheme,
    isDebugMode,
    setIsDebugMode,

    isAzure,
    setIsAzure,
    isPromptRephraseFlag,
    setIsPromptRephraseFlag,
    guidelines,
    setGuidelines,
    isIncludeFewShots,
    setIsIncludeFewShots
  } = useAppContext(); // Default selected option
  const toggleTheme = () => {
    let temp = !isDarkTheme;
    setIsDarkTheme(temp);
    toggleClick(temp);
  };
  const [instructionTemplate, setInstructionTemplate] = useState({
    generalInstructions: "",
    rephraseInstructions: "",
    intentClassification: "",
    promptSchemaCompression: "",
    texttoSQL: "",
    responseInstructions: ""
  });
  const fetchData = async () => {
    try {
      const data = await fetchGuidelines(); // Call the async function
      setGuidelines(data); // Set the data in state
    } catch (err) {
      console.error("Error fetching guidelines:", err);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const [dataSet, setDataSet] = useState({
    table1: "",
    table2: "",
    table3: ""
  });

  const handleInputChange = (setStateFunction: any, field: any, value: any) => {
    setStateFunction((prev: any) => ({ ...prev, [field]: value }));
  };
  const defaultModelValue = isAzure ? "gpt-4o" : "chatgpt-4o-latest";
  const defaultMaxResponse = 800;
  const defaultTemperature = 0;
  const defaultTopP = 1;
  const defaultPastMessage = 3;
  const handleReset = () => {
    setModelValue(defaultModelValue);
    setMaxResponse(defaultMaxResponse);
    setTemperature(defaultTemperature);
    setTopP(defaultTopP);
    setPastMessage(defaultPastMessage);
  };
  const toggleMode = () => {
    let temp = !isDebugMode;
    setIsDebugMode(temp);
  };
  const toggleEnvironment = () => {
    let temp = !isAzure;
    setIsAzure(temp);
    if (temp) {
      setModelValue("gpt-4o");
    } else {
      setModelValue("chatgpt-4o-latest");
    }
  };
  const handleOptionChange = (option: any) => {
    setModelValue(option);
  };
  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setModelValue(e.target.value);
  };
  const handleHideOnClickOutside = (event: any) => {
    console.log(event.target);
    if (!event.target.closest(".info-icon")) {
      // Check if clicked outside the modal
      console.log("Close");
    } else {
      console.log("Open");
    }
  };
  const renderTemplates = (prefix: any) => {
    const templates = [];

    for (const key in guidelines) {
      if (guidelines.hasOwnProperty(key)) {
        const isPrefixMatched = key.startsWith(prefix);
        if (
          (prefix === "tbl_" && isPrefixMatched) ||
          (prefix === "" && !isPrefixMatched)
        ) {
          templates.push(
            <div style={{ marginBottom: 16 }} key={key}>
              <label>{key}</label>
              <TextArea
                value={guidelines[key]}
                style={{
                  height: textAreaHeight[key] || 50,
                  transition: "height 0.3s"
                }}
                onFocus={() => handleFocus(key)}
                onBlur={() => handleBlur(key)}
                onChange={(e) => {
                  setGuidelines({
                    ...guidelines,
                    [key]: e.target.value
                  });
                }}
              />
            </div>
          );
        }
      }
    }
    return templates;
  };
  return (
    <CHeader position="sticky">
      <CContainer fluid className="px-0">
        <CRow className="w-100 mx-0">
          <CCol md={2} className="d-flex align-items-center">
            <CHeaderNav className="d-none d-md-flex">
              <CNavItem className="py-1 me-4">
                <img src={appLogo} alt="Logo" className="sidebar-icon" />
                {/* <h1 style={{ color: "white" }}>TCS</h1> */}
              </CNavItem>
            </CHeaderNav>
          </CCol>
          <CCol className="d-flex justify-content-center align-items-center">
            <CHeaderNav className="d-none d-md-flex mx-auto">
              <CNavItem>
                {/* <div className="headertitle">MAG-AI Companion</div> */}
                <CButton
                  color="primary"
                  onClick={toggleModal}
                  className="ChatDropDownToggle"
                >
                  MAG-AI Companion <CIcon icon={cilChevronBottom} size="sm" />
                </CButton>

                <CModal
                  visible={modalVisible}
                  onClose={() => {
                    setModalVisible(false);
                  }}
                  backdrop={true}
                  className="custom-modal"
                  onClick={handleHideOnClickOutside}
                >
                  <CModalHeader closeButton>
                    <CCardTitle>MAG-AI Companion Settings</CCardTitle>
                    <CTooltip
                      content="Reset to default settings"
                      placement="bottom"
                    >
                      <CIcon
                        style={{
                          cursor: "pointer"
                        }}
                        className="ms-2 mt-1"
                        onClick={handleReset}
                        icon={cilReload}
                      />
                    </CTooltip>
                    {/* <CButton
                      color="danger"
                      size="sm"
                      onClick={handleReset}
                      style={{ marginLeft: "auto" }}
                    >
                      Reset
                    </CButton> */}
                  </CModalHeader>
                  <CModalBody className="custom-modal-body">
                    <CCard>
                      <CCardBody>
                        <CCardText>
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="gpt-model">GPT Model</label>
                            </div>
                            <div className="col-md-8">
                              <CFormSelect
                                id="gpt-model"
                                name="GPT Model"
                                value={modelValue?.toString()}
                                onChange={handleModelChange}
                              >
                                {isAzure ? (
                                  <>
                                    <option value={"gpt-4o"}>
                                      GPT-4o-2024-08-06
                                    </option>
                                    <option value={"gpt-4o-mini"}>
                                      GPT-4o-mini-2024-07-18
                                    </option>
                                  </>
                                ) : (
                                  <>
                                    <option value={"chatgpt-4o-latest"}>
                                      ChatGPT-4o-latest
                                    </option>
                                    <option value={"gpt-4o-2024-08-06"}>
                                      GPT-4o-2024-08-06
                                    </option>
                                  </>
                                )}
                              </CFormSelect>
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <label htmlFor="max-response">
                                Max Response
                                <CPopover
                                  content="Set a limit on the number of tokens per model response. The API supports a maximum of MaxTokensPlaceholderDoNotTranslate tokens shared between the prompt (including system message, examples, message history, and user query) and the model's response. One token is roughly 4 characters for typical English text. 
                                Recommended: 1200"
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                  // trigger="focus"
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer"
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CInputGroup className="custom-input-group">
                                <div className="col-md-9">
                                  <CFormRange
                                    id="max-response"
                                    name="Max Response"
                                    min={0}
                                    max={8000}
                                    value={maxResponse?.toString()}
                                    onChange={(e) =>
                                      setMaxResponse(Number(e.target.value))
                                    }
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <CInputGroupText className="custom-input-group-text">
                                    {maxResponse}
                                  </CInputGroupText>
                                </div>
                              </CInputGroup>
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <label htmlFor="temperature">
                                Temperature
                                <CPopover
                                  content="Controls randomness. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses. Try adjusting temperature or Top P but not both. 
                                Recommended: 0"
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer"
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CInputGroup>
                                <div className="col-md-9">
                                  <CFormRange
                                    id="temperature"
                                    name="Temperature"
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    value={temperature?.toString()}
                                    onChange={(e) =>
                                      setTemperature(Number(e.target.value))
                                    }
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <CInputGroupText>
                                    {temperature}
                                  </CInputGroupText>
                                </div>
                              </CInputGroup>
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="top-p">
                                Top P
                                <CPopover
                                  content="Similar to temperature, this controls randomness but uses a different method. Lowering Top P will narrow the model’s token selection to likelier tokens. Increasing Top P will let the model choose from tokens with both high and low likelihood. Try adjusting temperature or Top P but not both. 
                                Recommended: 1"
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer"
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CInputGroup>
                                <div className="col-md-9">
                                  <CFormRange
                                    id="top-p"
                                    name="Top P"
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    value={topP?.toString()}
                                    onChange={(e) =>
                                      setTopP(Number(e.target.value))
                                    }
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <CInputGroupText>{topP}</CInputGroupText>
                                </div>
                              </CInputGroup>
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="top-p">
                                Past Messages Included
                                <CPopover
                                  content="Select the number of past messages to include in each new API request. This helps give the model context for new user queries. Setting this number to 10 will include 5 user queries and 5 system responses. 
                                Recommended: 3"
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer"
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CInputGroup>
                                <div className="col-md-9">
                                  <CFormRange
                                    id="pastmessageincluded"
                                    name="Past Messages Included"
                                    min={0}
                                    max={10}
                                    step={1}
                                    value={pastMessage?.toString()}
                                    onChange={(e) =>
                                      setPastMessage(Number(e.target.value))
                                    }
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <CInputGroupText>
                                    {pastMessage}
                                  </CInputGroupText>
                                </div>
                              </CInputGroup>
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="promp-rephrasing">
                                Prompt Rephrasing
                                <CPopover
                                  content="Rephrase the raw user prompt only if the current user prompt is incomplete or lacks context, by referencing the user's previous prompts and responses."
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer"
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CFormSwitch
                                className="custom-switch"
                                id="formSwitchCheckDefault"
                                checked={isPromptRephraseFlag}
                                onChange={(e) => {
                                  setIsPromptRephraseFlag(e.target.checked);
                                }}
                              />
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="promp-rephrasing">
                                Include FewShots
                                <CPopover
                                  content="Providing the model with a small number of examples will enhance its ability to produce reliable outcomes."
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer"
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CFormSwitch
                                className="custom-switch"
                                id="fewShotswitchCheckDefault"
                                checked={isIncludeFewShots}
                                onChange={(e) => {
                                  setIsIncludeFewShots(e.target.checked);
                                }}
                              />
                            </div>
                          </div>
                        </CCardText>
                      </CCardBody>
                    </CCard>
                  </CModalBody>
                </CModal>
              </CNavItem>
            </CHeaderNav>
          </CCol>
          <CCol
            md={2}
            className="d-flex justify-content-end align-items-center"
          >
            {/* <CHeaderNav>
              <CNavItem>
                <CNavLink className="PageTitle" href="/#/salesChat">
                  Sales
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink className="PageTitle" href="/#/Chat">
                  Home
                </CNavLink>
              </CNavItem>
            </CHeaderNav> */}
            <CHeaderNav className="profilelogo">
              <CNavItem className="header-guidelines-icon">
                <BsGear
                  className="ConfigurationIcon"
                  onClick={() => {
                    setConfigurationVisible((prevVisible) => !prevVisible);
                  }}
                />
              </CNavItem>
              {!showBamHeader ? (
                <CNavItem className="d-flex align-items-center pe-2">
                  <Switch
                    onChange={toggleEnvironment}
                    offColor="#808080"
                    onColor="#FFFFFF"
                    offHandleColor="#FFFFFF"
                    onHandleColor="#808080"
                    checked={isAzure}
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",

                          paddingRight: 2
                        }}
                      >
                        <img
                          src={azure}
                          alt="azure"
                          style={{ width: "13px" }}
                        />
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",

                          paddingRight: 2
                        }}
                      >
                        <img
                          src={openai}
                          alt="openai"
                          style={{ width: "13px" }}
                        />
                      </div>
                    }
                    uncheckedHandleIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",

                          paddingRight: 2
                        }}
                      >
                        <img
                          src={openai}
                          alt="openai"
                          style={{ width: "13px" }}
                        />
                      </div>
                    }
                    checkedHandleIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%"
                        }}
                      >
                        <img
                          src={azure}
                          alt="azure"
                          style={{ width: "13px" }}
                        />
                      </div>
                    }
                    className="reactswitch me-2"
                  />
                  <Switch
                    onChange={toggleMode}
                    offColor="#808080"
                    onColor="#FFFFFF"
                    offHandleColor="#FFFFFF"
                    onHandleColor="#808080"
                    checked={isDebugMode}
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",

                          paddingRight: 2
                        }}
                      >
                        <img
                          src={debug}
                          alt="debug"
                          style={{ width: "13px" }}
                        />
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",

                          paddingRight: 2
                        }}
                      >
                        <img src={user} alt="user" style={{ width: "13px" }} />
                      </div>
                    }
                    uncheckedHandleIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",

                          paddingRight: 2
                        }}
                      >
                        <img src={user} alt="user" style={{ width: "13px" }} />
                      </div>
                    }
                    checkedHandleIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%"
                        }}
                      >
                        <img
                          src={debug}
                          alt="debug"
                          style={{ width: "13px" }}
                        />
                      </div>
                    }
                    className="reactswitch me-2"
                  />
                  {/* <Switch
                    onChange={toggleTheme}
                    offColor="#808080"
                    onColor="#FFFFFF"
                    offHandleColor="#FFFFFF"
                    onHandleColor="#808080"
                    checked={isDarkTheme}
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",

                          paddingRight: 2,
                        }}
                      >
                        <img src={dark} alt="dark" />
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",

                          paddingRight: 2,
                        }}
                      >
                        <img src={light} alt="light" />
                      </div>
                    }
                    uncheckedHandleIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",

                          paddingRight: 2,
                        }}
                      >
                        <img src={light} alt="light" />
                      </div>
                    }
                    checkedHandleIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <img src={dark} alt="light" />
                      </div>
                    }
                    className="reactswitch"
                  /> */}
                </CNavItem>
              ) : (
                ""
              )}
              <CNavItem className="d-flex align-items-center">
                {/* <CAvatar
                  className="profileAvatar"
                  data-coreui-toggle="tooltip"
                  data-coreui-placement="bottom"
                  title={userDetails?.[0]?.userName}
                  textColor="white"
                  data-testid="userName"
                >
                  {userDetails.length > 0 &&
                    getInitials(userDetails?.[0]?.userName)}
                </CAvatar> */}
                <CDropdown className="d-flex align-items-center">
                  <CDropdownToggle
                    as="div"
                    className="p-0 custom-dropdown-toggle"
                    style={{
                      border: "none",
                      background: "transparent",
                      cursor: "pointer"
                    }}
                  >
                    <CNavItem className="d-flex align-items-center">
                      <CAvatar
                        className="profileAvatar"
                        data-coreui-toggle="tooltip"
                        data-coreui-placement="bottom"
                        title={userDetails?.[0]?.userName}
                        textColor="white"
                        data-testid="userName"
                      >
                        {userDetails.length > 0 &&
                          getInitials(userDetails?.[0]?.userName)}
                      </CAvatar>
                    </CNavItem>
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem className="custom-dropdown-item">
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{isDarkTheme ? "Dark Mode" : "Light Mode"}</span>
                        <Switch
                          onChange={toggleTheme}
                          offColor="#808080"
                          onColor="#FFFFFF"
                          offHandleColor="#FFFFFF"
                          onHandleColor="#808080"
                          checked={isDarkTheme}
                          uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",

                                paddingRight: 2
                              }}
                            >
                              <img src={dark} alt="dark" />
                            </div>
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",

                                paddingRight: 2
                              }}
                            >
                              <img src={light} alt="light" />
                            </div>
                          }
                          uncheckedHandleIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",

                                paddingRight: 2
                              }}
                            >
                              <img src={light} alt="light" />
                            </div>
                          }
                          checkedHandleIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%"
                              }}
                            >
                              <img src={dark} alt="light" />
                            </div>
                          }
                          className="reactswitch ms-2 border"
                        />
                      </div>
                    </CDropdownItem>
                    <CDropdownItem className="custom-dropdown-item">
                      <CNavItem>
                        <CNavLink
                          onClick={() => handleLogout("redirect")}
                          data-testid="logoutIcon"
                        >
                          Signout
                          <CAvatar className="circleIcon">
                            <span className="logoutSpriteIcon"></span>
                          </CAvatar>
                        </CNavLink>
                      </CNavItem>
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CNavItem>
              {/* <CNavItem>
                <CNavLink
                  onClick={() => handleLogout("redirect")}
                  data-testid="logoutIcon"
                >
                  <CAvatar className="circleIcon">
                    <span className="logoutSpriteIcon"></span>
                  </CAvatar>
                </CNavLink>
              </CNavItem> */}
            </CHeaderNav>
          </CCol>
        </CRow>
        <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          visible={configurationVisible}
        >
          <Button
            style={{ position: "absolute", top: 74, right: 40, zIndex: "999" }}
            onClick={() => setConfigurationVisible(false)}
          >
            <IoClose size={24} />
          </Button>

          <Tabs defaultActiveKey="1">
            <TabPane tab="Instruction Template" key="1">
              {guidelines &&
                Object.keys(guidelines).map((key) => {
                  if (!key.startsWith("tbl_")) {
                    return (
                      <div key={key} style={{ marginBottom: 16 }}>
                        <label
                          style={{
                            display: "block",
                            backgroundColor: "#f0f2f5",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginBottom: "4px",
                            border: "1px solid #d9d9d9"
                          }}
                        >
                          {key === "TexttoSQLTemplate"
                            ? "Text To SQL Template"
                            : key.replace(/([A-Z])/g, " $1").trim()}
                        </label>
                        <TextArea
                          value={guidelines[key]}
                          style={{
                            height: textAreaHeight?.[key] || 120,
                            transition: "height 0.3s"
                          }}
                          onFocus={() => handleFocus(key)}
                          onBlur={() => handleBlur(key)}
                          onChange={(e) => {
                            setGuidelines({
                              ...guidelines,
                              [key]: e.target.value
                            });
                          }}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              <Row justify="end">
                <Col>
                  <Button
                    type="primary"
                    style={{ marginRight: 8 }}
                    onClick={() => setConfigurationVisible(false)}
                  >
                    Save
                  </Button>
                  <Button
                    type="default"
                    style={{ marginRight: 8 }}
                    onClick={handleResetGuideLines}
                  >
                    Reset
                  </Button>
                  {GetRoles().includes("SalesICompanion.Admin") && (
                    <Button type="default" onClick={handleDeploy}>
                      Deploy
                    </Button>
                  )}
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="Data Table Instructions" key="2">
              {guidelines &&
                Object.keys(guidelines).map((key) => {
                  if (key.startsWith("tbl_")) {
                    return (
                      <div key={key} style={{ marginBottom: 16 }}>
                        <label
                          style={{
                            display: "block",
                            backgroundColor: "#f0f2f5",
                            padding: "8px 12px",
                            borderRadius: "4px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginBottom: "4px",
                            border: "1px solid #d9d9d9"
                          }}
                        >
                          {key.replace(/([A-Z])/g, " $1").trim()}
                        </label>
                        <TextArea
                          value={guidelines[key]}
                          style={{
                            height: textAreaHeight?.[key] || 120,
                            transition: "height 0.3s"
                          }}
                          onFocus={() => handleFocus(key)}
                          onBlur={() => handleBlur(key)}
                          onChange={(e) => {
                            setGuidelines({
                              ...guidelines,
                              [key]: e.target.value
                            });
                          }}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              <Row justify="end">
                <Col>
                  <Button
                    type="primary"
                    style={{ marginRight: 8 }}
                    onClick={() => setConfigurationVisible(false)}
                  >
                    Save
                  </Button>
                  <Button
                    type="default"
                    style={{ marginRight: 8 }}
                    onClick={handleResetGuideLines}
                  >
                    Reset
                  </Button>
                  {GetRoles().includes("SalesICompanion.Admin") && (
                    <Button type="default" onClick={handleDeploy}>
                      Deploy
                    </Button>
                  )}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Drawer>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
