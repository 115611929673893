import { useRef, useState, useEffect } from "react";
import mobstyles from "./MobileChat.module.css";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import {
  DefaultButton,
  IDropdownOption,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
  Tooltip,
  TooltipHost,
} from "@fluentui/react";
import {
  DismissRegular,
  SquareRegular,
  ErrorCircleRegular,
  Speaker228Filled,
  SpeakerMute28Regular,
} from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import styles from "./Chat.module.css";

import {
  ChatMessage,
  ConversationRequest,
  conversationApi,
  Citation,
  ToolMessageContent,
  ChatResponse,
  getUserInfo,
  invokeApi,
  sendEmaiApi,
  invokeApi1,
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import {
  SystemAnswer,
  SystemResponse,
} from "../../components/Answer/SystemAnswer";
import React from "react";
import { useMsal } from "@azure/msal-react";
import AppHeader from "../../components/AppHeader";
import { GetRoles, GetUserName } from "../../services/AuthUser";
import RecommendationTab from "../../components/Recommendation/RecommendationTab";
import { useAppContext } from "../../components/ContextAndProvider/Context";
import { CButton, CCol, CModal, CPopover, CRow } from "@coreui/react";
import { getInitials } from "../../components/CommonUtils/CommonUtils";
import {
  setJsonItem,
  getAllItems,
  generateSessionId,
} from "../../services/LocalStorageService";
import QueSuggestion from "./QueSuggestion";
import MobileDropdown from "./MobileDropdown";
import GramerlyContent from "./GramerlyContent";
import { Dropdown, MenuProps } from "antd";
import { sample } from "lodash";
import { useLocation } from "react-router-dom";
interface ChildProps {
  childFunction: () => void;
}
const Chat = () => {
  const {
    isSidebarOpen,
    setSidebarOpen,
    selectedSystem,
    setSelectedSystem,
    activeRecommendation,
    setActiveRecommendation,
    isMobile,
    setIsMobile,
    popoverVisible,
    isFocusTextField,
    setIsFocusTextField,
    gramerlyContent,
    setGramerlyContent,
    setActiveGrammar,
    answers,
    setAnswers,
    grammarPopVisible,
    setGrammarlyPopVisible,
    activeGrammar,
    sampleQnPopVisible,
    setSampleQnPopVisible,
    modelValue,
    setModelValue,
    maxResponse,
    temperature,
    topP,
    pastMessage,
    setPastMessage,
  } = useAppContext();
  let userDetails = GetUserName();
  const { instance } = useMsal();
  const [player, updatePlayer] = useState<{
    p: sdk.SpeakerAudioDestination | undefined;
    muted: boolean;
  }>({ p: undefined, muted: false });
  const lastQuestionRef = useRef<string>("");
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMute, setIsMute] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [GrammmarVisibility, setGrammarVisibility] = useState<boolean>(false);

  const [activeCitation, setActiveCitation] =
    useState<
      [
        content: string,
        id: string,
        title: string,
        filepath: string,
        url: string,
        metadata: string
      ]
    >();
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    useState<boolean>(false);
  // const [answers, setAnswers] = useState<ChatMessage[]>([]);
  const abortFuncs = useRef([] as AbortController[]);
  const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);
  const [isSpeechStarted, setIsSpeechStarted] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPsModalOpen, setIsPsModalOpen] = useState(false);
  const [smtpProvider, setSmtpProvider] = useState("smtp.hm.com");
  const [senderEmailID, setSenderEmailID] = useState("user1@hm.com");
  const [appPassword, setAppPassword] = useState("");
  const [receiverEmailID, setReceiverEmailID] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [psscript, setPsScript] = useState("");
  const [isToggled, setIsToggled] = useState(true);
  const [indexName, setIndexName] = useState(
    "yourdata-20230609092735-afc9-index-chunk"
  );
  const salesIntelligentPromtQuest = [
    "Top 10 products with the highest sales volume",
    "Which US store sold more products",
    "Which product sold out most on 6th May,2024 in sweden",
  ];
  const options: IDropdownOption[] = [
    { key: "yourdata-20230609092735-afc9-index-chunk", text: "EnterpriseDS" },
    { key: "genaiopsteps", text: "KnowledgeDS" },
    { key: "insightind", text: "Insight" },
    { key: "addmemberinedpoc2", text: "ActionDS" },
    { key: "insightindexnew", text: "Monitoring" },
  ];
  const indexKeyWord: { index: string; keywords: string[] }[] = [
    { index: "addmember-index", keywords: ["add member", "edpoc", "ED-Poc"] },
  ];
  let SampleQuestion: {
    title: string;
    index: string;
    suggestedQuestions: string[];
    indexName?: string;
    description: string;
  }[] = [
    {
      title: "HAI Buddy",
      index: "induction-index",
      suggestedQuestions: [
        "What is HAI Buddy?",
        "Give a summary about H&M in two lines",
        "What is the relationship of TCS with H&M in two lines?",
        "Who is the current CEO of H&M?",
        "Type your own question below",
      ],
      description:
        "HAI(HM.AI) Buddy is a reliable and trustworthy digital friend that can assist users in various aspects of their daily lives.",
    },

    {
      title: "Business Impact (P1/Major Incident)",
      indexName: "Business Impact",
      index: "p1-incident-data-index",
      suggestedQuestions: [
        "What are the current ongoing incidents?",
        "What is the business impact of current incidents?",
        "What are the incident numbers where US Store are impacted?",
        "Type your own question below",
      ],
      description:
        "The module shows a severe business impact, causing significant financial losses and disrupting critical operations.",
    },
    {
      title: "Operational AI Companion (Vertex)",
      indexName: "Vertex",
      index: "vertex-index",
      suggestedQuestions: [
        "Why Vertex?",
        "What is a VST?",
        "Can you share vendor contact information for Vertex?",
        "Total Incidents received for Vertex in last year?",
        "Current status of vertex application?",
        "Type your own question below",
      ],
      description:
        "Operational AI Companion (Vertex) is a tax engine used by businesses for tax calculation and compliance (United States, Canada).",
    },
    {
      title: "Store Network",
      indexName: "Store Network",
      index: "instorecare-index",
      suggestedQuestions: [
        "Can you share health status of SE0034 ?",
        "Can you share WAN status of SE0145 ?",
        "How many access points are connected in SE0145 ?",
        "Does store  SE0152 has any routers down ?",
        "Type your own question below",
      ],
      description:
        "The Store Network Section is responsible for providing comprehensive updates on the overall health and status of the store network.",
    },
  ];
  if (GetRoles().includes("SalesInsights.Users")) {
    SampleQuestion = [
      ...SampleQuestion,
      {
        title: "Sales Intelligent",
        indexName: "Sales Intelligent",
        index: "storesalesdata-index",
        suggestedQuestions: [
          "Which Sweden store did more sales ?",
          "Which is the most preferred size for adults in US ?",
          "What color product for kids is the best-selling in Sweden ?",
        ],
        description:
          "The Sales Intelligent section provides visibility into the sales process across multiple stores, enabling better analysis and decision-making.",
      },
    ];
  }
  const { isDarkTheme, setIsDarkTheme } = useAppContext();
  const toggleTheme = (data: any) => {
    // setIsDarkTheme(data);
  };
  const location = useLocation();
  useEffect(() => {
    // Perform setup or subscribe to something
    console.log('Moved to chat page');
    return () => {
      //clearChat();
      console.log('Component is being unmounted');
    };
  }, []);
  console.log("GetRoles()", GetRoles());
  if (GetRoles().includes("Store.Managar")) {
    SampleQuestion = [
      ...SampleQuestion,
      {
        title: "Store Manager",
        index: "store-index",
        suggestedQuestions: [
          "Does start of the day report received for all stores?",
          "Which store has more visitors count yesterday?",
          "Which india store has less visitors day before yesterday ?",
          "Which US store in has more visitors yesterday then day before yesterday?",
          "Type your own question below",
        ],
        description:
          "With AI Companion, store managers can easily manage inventory, track sales, and analyze customer data to make informed business decisions.",
      },
    ];
  }
  if (GetRoles().length === 1 && GetRoles()[0] === "BusinessImpact.Users") {
    SampleQuestion = [
      {
        title: "Business Impact (P1/Major Incident)",
        indexName: "Business Impact",
        index: "p1-incident-data-index",
        suggestedQuestions: [
          "What are the current ongoing incidents?",
          "What is the business impact of current incidents?",
          "What are the incident numbers where US Store are impacted?",
          "Type your own question below",
        ],
        description:
          "The module shows a severe business impact, causing significant financial losses and disrupting critical operations.",
      },
    ];
  }
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    "yourdata-20230609092735-afc9-index-chunk"
  );
  const [spokenText, setSpokenText] = useState<string>("");
  const speechConfig = sdk.SpeechConfig.fromSubscription(
    "1221987474544498aeabb29a4ce8167c",
    "westeurope"
  );
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedIndexName, setSelectedIndexName] = useState("");
  const [historyData, setHistoryData] = useState<ChatMessage[]>([]);
  const [sessionID, setSessionID] = useState("");
  const [groupedData, setGroupedData] = useState<Record<string, any[]>>({});
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  // Function to toggle the popover
  const togglePopover = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (!sessionID) {
      const sessionId = generateSessionId();
      setSessionID(sessionId);
    }
  }, []);
  const groupByDate = (inputData: any[]) => {
    return inputData.reduce((result: Record<string, any[]>, item) => {
      const key = Object.keys(item)[0].split("-")[1];
      result[key] = result[key] || [];
      result[key].push(item);
      return result;
    }, {});
  };
  const handleSave = () => {
    setJsonItem(sessionID, answers);
  };
  let currentSynthesisOperation: any = null;
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const speakText = (text: string) => {
    const myPlayer = new sdk.SpeakerAudioDestination();
    updatePlayer((p) => {
      p.p = myPlayer;
      return p;
    });
    const audioConfig = sdk?.AudioConfig?.fromSpeakerOutput(myPlayer);
    speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
    let synthesizer: sdk.SpeechSynthesizer | undefined =
      new sdk.SpeechSynthesizer(speechConfig, audioConfig);
    if (!isMute && synthesizer) {
      synthesizer?.speakTextAsync(
        text?.toString(),
        (result) => {
          let outputText;
          if (
            result?.reason === sdk?.ResultReason?.SynthesizingAudioCompleted
          ) {
            outputText = `Synthesis finished for "${text}".\n`;
          } else if (result?.reason === sdk?.ResultReason?.Canceled) {
            outputText = `Synthesis failed. Error detail: ${result?.errorDetails}.\n`;
          }
          synthesizer!.close();
          setIsSpeechStarted(true);
          myPlayer.onAudioEnd = function () {
            setIsSpeechStarted(false);
          };
        },
        (err) => {
          console.log(`Error: ${err}.\n`);
          synthesizer!.close();
        }
      );
    } else {
      console.log("Synthesizer is undefined or isMute is true.");
    }
  };
  // Function to stop the ongoing speech synthesis.
  async function stopSpeaking() {
    setIsMute(true);
    updatePlayer((p) => {
      if (p?.p) {
        if (!p?.muted) {
          //setIsMute(true);
          p?.p?.mute();
          p?.p?.close();
        } else {
          //setIsMute(false);
          // p.p.resume();
        }
      }
      return { ...p, muted: !p?.muted };
    });
  }

  const handleDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    if (item) {
      setSelectedOption(item?.key as string);
      setIndexName(item?.key as string);
      // Perform any other actions you want based on the selected value
    }
  };
  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);
  const hidePsModal = () => setIsPsModalOpen(false);
  const _onchangeIndex = () => {
    if (!isToggled) {
      setIndexName("yourdata-20230609092735-afc9-index-chunk");
    } else {
      setIndexName("genaiopsteps");
    }
    setIsToggled(!isToggled); // Toggle the state when the switch is clicked
  };
  const _onchangeMAIndex = () => {
    setIsToggled(!isToggled); // Toggle the state when the switch is clicked
  };

  const showEmailModal = () => {
    if (
      answers?.length > 0 &&
      answers[answers?.length - 1]?.role == "assistant"
    ) {
      const lines = answers[answers?.length - 1]?.content?.split("\n");
      const tempcontent = answers[answers?.length - 1]?.content;
      const subjectIndex = tempcontent?.indexOf("Subject:");
      let subject = "";
      let content = "";

      if (subjectIndex !== -1) {
        subject = tempcontent
          ?.substring(
            subjectIndex + "Subject:"?.length,
            tempcontent?.indexOf("\n", subjectIndex)
          )
          ?.trim();

        // Extract the content after the subject
        content = tempcontent
          ?.substring(tempcontent?.indexOf("\n", subjectIndex) + 1)
          ?.trim();
      }

      setSubject(subject);
      setContent(content);
    }
    setIsModalOpen(true);
  };
  function getLastAssistantRole(data: any[]): any | null {
    for (let i = data?.length - 1; i >= 0; i--) {
      if (data[i]?.role === "assistant") {
        return data[i];
      }
    }
    return null;
  }
  const onExecute = async () => {
    const response = await invokeApi(psscript);
    const systemMessage: ChatMessage = {
      role: "systemAnswer",
      content: "Powershell Script Executed Successfully",
    };
    setAnswers([...answers, systemMessage]);
    hidePsModal();
  };
  const onSend = async () => {
    const script = `$smtpServer = "${smtpProvider}"
    $smtpPort = "587"
    $username = "${senderEmailID}"
    $password = "${appPassword}"
    $to = "${receiverEmailID}"
    $subject = "${subject}"
    $body = "${content}"
    $from =  "${senderEmailID}"
    
    $smtp = New-Object System.Net.Mail.SmtpClient($smtpServer, $smtpPort)
    $smtp.EnableSSL = $true
    $smtp.Credentials = New-Object System.Net.NetworkCredential($username, $password)
    $mailMessage = New-Object System.Net.Mail.MailMessage($from, $to, $subject, $body)
    $smtp.Send($mailMessage)`;

    const response = await sendEmaiApi(script);
    const systemMessage: ChatMessage = {
      role: "systemAnswer",
      content: "Email Sent Successfully",
    };
    setAnswers([...answers, systemMessage]);
    hideModal();
  };
  const getUserInfoList = async () => {
    const userInfoList = await getUserInfo();
    if (
      userInfoList?.length === 0 &&
      window?.location?.hostname !== "127.0.0.1"
    ) {
      setShowAuthMessage(true);
    } else {
      setShowAuthMessage(false);
    }
  };
  const successFunc = async (ans: ChatMessage[], email: string) => {
    const response = await invokeApi1(email);
    const updatedData = ans?.map((obj) => {
      if (obj?.role === "systemQuestion") {
        return {
          role: "systemAnswer",
          content: obj?.email + " added successfully",
        };
      }
      return obj;
    });
    setAnswers(updatedData);
  };
  const cancelFunc = async (ans: ChatMessage[], email: string) => {
    const response = await invokeApi(email);
    const updatedData = ans?.filter((obj) => obj?.role !== "systemQuestion");
    setAnswers(updatedData);
  };
  const noAnsFunc = async (ans: ChatMessage[], email: string) => {
    const response = await invokeApi(email);
    const updatedData = ans?.map((obj) => {
      if (obj?.role === "systemQuestion") {
        return {
          role: "systemQuestion",
          content:
            "If you want to add others email address, Please enter the email and send",
          callback: successFunc,
          noAnsCallback: cancelFunc,
          email: "",
        };
      }
      return obj;
    });
    setAnswers(updatedData);
  };

  const replaceINCWithLink = (content: string): string => {
    const words = content.split(/\b/);
    const updatedWords = words.map((word) =>
      word.includes("INC")
        ? `[${word}](https://hm.service-now.com/nav_to.do?uri=incident.do?sysparm_query=number=${word.replace(
            /[^\w\s]/g,
            ""
          )})`
        : word
    );
    return updatedWords.join("");
  };
  const replaceLinkWithINC = (content: string): string => {
    return content.replace(
      /\[([A-Z]+\d+)\]\(https:\/\/hm\.service-now\.com\/nav_to\.do\?uri=incident\.do\?sysparm_query=number=\1\)/g,
      "$1"
    );
  };
  const replaceUpDownWithLink = (content: string): string => {
    const words = content.split(/\b/);
    console.log("##########Content", content);
    const updatedWords = words.map((word) =>
      word.trim() == "Up" || word.trim() == "Green"
        ? `${word} ![GEllipse SVG](/images/GEllipse.svg)`
        : word.trim() == "Down" || word.trim() == "Red"
        ? `${word} ![REllipse SVG](/images/REllipse.svg)`
        : word
    );
    return updatedWords.join("");
  };
  const getLabels = (): string[] => {
    const labels: string[] = [];

    // Iterate over each category (Rephrase and AdjustTone)
    for (const category of Object.values(gramerlyContent)) {
      // Iterate over items in the category
      for (const item of category) {
        // If value is true, push the label to the array
        if (item.value) {
          labels.push(item.label);
        }
      }
    }

    return labels;
  };
  const getIndexName = (question: string) => {
    let tempidx = "";
    indexKeyWord.forEach((element) => {
      let hasMatch = element.keywords.some((word) => {
        let regex = new RegExp("\\b" + word + "\\b", "i");
        return regex.test(question);
      });
      if (hasMatch) {
        tempidx = element.index;
      }
    });
    return tempidx;
  };
  const repostQuestion = (Selectedgramerlycontent: string) => {
    setGrammarlyPopVisible(false);
    makeApiRequest(
      lastQuestionRef.current,
      selectedIndex,
      Selectedgramerlycontent
    );
  };
  const makeApiRequest = async (
    question: string,
    idx?: string,
    selectedGramerly?: string
  ) => {
    const regex = /@hm\.com\b/i;
    const lastanswer = answers[answers?.length - 1];

    if (
      question.match(regex) &&
      lastanswer &&
      lastanswer.content ==
        "If you want to add others email address, Please enter the email and send"
    ) {
      const regex2 = /\b(\S+@hm\.com)\b/;
      const match = question?.match(regex2);
      const wordWithHmEmail = match ? match[1] : "";
      const response = await invokeApi(wordWithHmEmail);
      const updatedData = answers?.map((obj) => {
        if (obj?.role === "systemQuestion") {
          return {
            role: "systemAnswer",
            content: wordWithHmEmail + " added successfully",
          };
        }
        return obj;
      });
      setAnswers(updatedData);
    } else {
      lastQuestionRef.current = question;

      setIsLoading(true);
      setShowLoadingMessage(true);
      const abortController = new AbortController();
      abortFuncs?.current?.unshift(abortController);
      let trueGrammerlyContent = getLabels();
      let gramerlyresult = trueGrammerlyContent.map((item) => {
        if (item) {
          return `\n- "${item}"`;
        } else {
          return "";
        }
      });
      let gramerContent = selectedGramerly ? selectedGramerly : activeGrammar;
      const finalqn =
        question + " GrammerlyContent " + "\n- " + '"' + gramerContent + '"';
      const userMessage: ChatMessage = {
        role: "user",
        content: gramerContent ? finalqn : question,
      };
      const filteredAnswers: ChatMessage[] = answers?.filter(
        (item) =>
          item?.role !== "systemAnswer" && item?.role !== "systemQuestion"
      );
      const request: ConversationRequest = {
        messages: [...filteredAnswers, userMessage],
      };
      const filteredMessages: any[] = request.messages.map((message: any) => {
        if (
          message.role === "user" &&
          message.content.includes("GrammerlyContent")
        ) {
          // Replace "GrammerlyContent" with an empty string
          return {
            ...message,
            content: message.content.replace("GrammerlyContent", ""),
          };
        }
        if (
          message.role === "assistant" &&
          (message.content.includes("![GEllipse SVG](/images/GEllipse.svg)") ||
            message.content.includes("![REllipse SVG](/images/REllipse.svg)"))
        ) {
          // Replace "GrammerlyContent" with an empty string
          let modifiedContent = message.content;

          // Define the substrings to replace
          const substringsToReplace = [
            "![GEllipse SVG](/images/GEllipse.svg)",
            "![REllipse SVG](/images/REllipse.svg)",
          ];

          // Iterate over each substring and replace all occurrences
          substringsToReplace.forEach((substring) => {
            const regex = new RegExp(
              substring.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
              "g"
            );
            modifiedContent = modifiedContent.replace(regex, "");
          });
          return {
            ...message,
            content: modifiedContent,
          };
        }
        if (
          message.role === "assistant" &&
          message.content.includes("https://hm.service-now.com/nav_to.do?")
        ) {
          let modifiedContent = replaceLinkWithINC(message.content);
          return {
            ...message,
            content: modifiedContent,
          };
        }
        return message;
      });

      // Assign the filtered messages back to a ConversationRequest object
      const filteredRequest: ConversationRequest = {
        messages: filteredMessages,
      };
      function trimStringToLines(inputString: string, lineLimit: number) {
        const lines = inputString?.split("\n");
        if (lines?.length <= lineLimit) {
          return inputString;
        } else {
          return lines?.slice(0, lineLimit)?.join("\n");
        }
      }
      function trimStringToWords(inputString: string, wordLimit: number) {
        const words = inputString?.split(/\s+/);
        if (words?.length <= wordLimit) {
          return inputString;
        } else {
          return words?.slice(0, wordLimit)?.join(" ");
        }
      }
      let result = {} as ChatResponse;
      let containsRoleToolAndContent = false;
      try {
        let defaultques = [
          "ed-poc",
          "edpoc",
          "Enterprise dashboard access",
          "SOP for ED",
          "dashboard acces",
          "Enterprise dashboard",
          "SharePoint",
          "mailbox",
        ];
        let hasMatch = defaultques?.some((word) => {
          let regex = new RegExp("\\b" + word + "\\b", "i");
          return regex?.test(question);
        });
        let index = hasMatch
          ? "genaiopsteps"
          : "yourdata-20230609092735-afc9-index-chunk";
        let selectedIdx = idx ? idx : selectedIndex;
        let actionidx = getIndexName(question);
        if (actionidx) {
          selectedIdx = actionidx;
        }
        const response = await conversationApi(
          filteredRequest,
          abortController?.signal,
          selectedIdx,
          modelValue,
          maxResponse,
          temperature,
          topP,
          pastMessage
        );
        if (response?.body) {
          const reader = response?.body?.getReader();
          let runningText = "";
          while (true) {
            const { done, value } = await reader?.read();
            if (done) break;

            var text = new TextDecoder("utf-8")?.decode(value);
            const objects = text?.split("\n");

            let lastmsg = "";
            objects?.forEach((obj, i) => {
              try {
                runningText += obj;
                result = JSON.parse(runningText);
                setShowLoadingMessage(false);

                const updatedMessages = result.choices[0].messages.map(
                  (message) => {
                    if (
                      message.role === "assistant" &&
                      message.content.includes("INC")
                    ) {
                      return {
                        ...message,
                        content: replaceINCWithLink(message.content),
                      };
                    }
                    const regex = /(Up|Green|Down|Red)/gi;
                    const containsWord = regex.test(message.content);

                    if (message.role === "assistant" && containsWord) {
                      let modifiedContent = message.content;

                      // Define the substrings to replace
                      const substringsToReplace = [
                        "![GEllipse SVG](/images/GEllipse.svg)",
                        "![REllipse SVG](/images/REllipse.svg)",
                      ];

                      // Iterate over each substring and replace all occurrences
                      substringsToReplace.forEach((substring) => {
                        const regex = new RegExp(
                          substring.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
                          "g"
                        );
                        modifiedContent = modifiedContent.replace(regex, "");
                      });

                      return {
                        ...message,
                        content: replaceUpDownWithLink(modifiedContent),
                      };
                    }
                    return message;
                  }
                );

                setAnswers([...answers, userMessage, ...updatedMessages]);
                let responsemsg =
                  result &&
                  result?.choices &&
                  result?.choices[0] &&
                  result?.choices[0]?.messages
                    ? result?.choices[0]?.messages
                    : "";

                runningText = "";
                if (result?.references?.includes("AddMember3.docx")) {
                  containsRoleToolAndContent = true;
                }

                // for (const choice of result?.choices) {
                //   for (const message of choice?.messages) {
                //     if (
                //       message?.role === "tool" &&
                //       message?.content?.includes("AddMember3.docx")
                //     ) {
                //       containsRoleToolAndContent = true;
                //       break;
                //     }
                //   }
                // }
              } catch {}
            });
          }
        }
      } catch (e) {
        if (!abortController?.signal?.aborted) {
          console.error(e);
          console.error(result);
        }
        setAnswers([...answers, userMessage]);
      } finally {
        try {
          if (containsRoleToolAndContent) {
            let userEmail = sessionStorage?.getItem("userSessionEmail") || "";

            const systemMessage: ChatMessage = {
              role: "systemQuestion",
              content:
                "Do you want to add your emailid " +
                userEmail +
                " in ED-POC-Users?",
              callback: successFunc,
              noAnsCallback: noAnsFunc,
              email: userEmail,
            };
            setAnswers([
              ...answers,
              userMessage,
              ...result?.choices[0]?.messages,
              systemMessage,
            ]);
            const regex = /@hm\.com\b/i;
            if (question.match(regex)) {
              const regex2 = /\b(\S+@hm\.com)\b/;
              const match = question.match(regex2);
              const wordWithHmEmail = match ? match[1] : null;
              const systemMessage: ChatMessage = {
                role: "systemQuestion",
                content:
                  "Do you want to add " + wordWithHmEmail + " in ED-POC-Users?",
                callback: successFunc,
                noAnsCallback: noAnsFunc,
                email: wordWithHmEmail ? wordWithHmEmail : "",
              };
              setAnswers([
                ...answers,
                userMessage,
                ...result.choices[0].messages,
                systemMessage,
              ]);
            }
          }
          containsRoleToolAndContent = false;
          setIsLoading(false);
          setShowLoadingMessage(false);

          abortFuncs.current = abortFuncs?.current?.filter(
            (a) => a !== abortController
          );
          let finalmessage = "";
          result?.choices[0]?.messages?.forEach((element) => {
            if (element?.role == "assistant") {
              finalmessage = element?.content;
            }
          });
          if (finalmessage) {
            if (finalmessage?.length <= 500) {
              speakText(finalmessage);
            }
          }

          console.log("result", result);
        } catch (e) {}
      }

      return abortController?.abort();
    }
  };

  const clearChat = () => {
    console.log("Clearing chat");
    updatePlayer((p) => {
      console.log("updatePlayer");
      if (p?.p) {
        console.log("if (p?.p)");
        if (!p?.muted) {
          console.log("p?.p?.mute();");
          //setIsMute(true);
          p?.p?.mute();
          p?.p?.close();
        } else {
          //setIsMute(false);
          // p.p.resume();
        }
      }
      return { ...p, muted: !p?.muted };
    });
    handleSave();
    lastQuestionRef.current = "";
    setActiveCitation(undefined);
    setAnswers([]);
    setSelectedIndex("");
    setSelectedIndexName("");
    const sessionId = generateSessionId();
    setSessionID(sessionId);
    setSelectedSystem("");
    setSidebarOpen(false);
  };

  const stopGenerating = () => {
    abortFuncs?.current?.forEach((a) => a?.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfoList();
  }, []);

  useEffect(
    () => chatMessageStreamEnd?.current?.scrollIntoView({ behavior: "smooth" }),
    [showLoadingMessage]
  );
  useEffect(() => {
    handleSave();
  }, [answers]);
  const onSuggestionQNClick = (
    question: string,
    indexName: string,
    index: string
  ) => {
    console.log("IndexName", index);
    if (question != "Type your own question below") {
      setSelectedIndex(index);
      setSelectedIndexName(indexName);
      makeApiRequest(question, index);
    } else {
      setSelectedIndex(index);
      setSelectedIndexName(indexName);
      // const elements = document.querySelectorAll(".dropdown-content");
      // elements.forEach((element) => {
      //   console.log("Element", element);
      //   (element as HTMLElement).style.display = "none";
      // });
      setIsFocusTextField(true);
    }
  };
  const onSampleQNClick = (item: {
    title: string;
    indexName?: string;
    index: string;
    description: string;
  }) => {
    setSelectedIndex(item?.index);
    setSelectedSystem(item?.title);
    setSelectedIndexName(item?.indexName ? item?.indexName : item?.title);
  };
  const onShowCitation = (citation: Citation) => {
    setActiveCitation([
      citation?.content,
      citation?.id,
      citation?.title ?? "",
      citation?.filepath ?? "",
      "",
      "",
    ]);
    setIsCitationPanelOpen(true);
  };

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message?.role === "tool") {
      try {
        const toolMessage = JSON.parse(message?.content) as ToolMessageContent;
        return toolMessage?.citations;
      } catch {
        return [];
      }
    }
    return [];
  };
  const setHistoryToCurrent = (historydata: ChatMessage[]) => {
    setAnswers(historydata);
    lastQuestionRef.current = "History added";
  };
  const handleRecommendationClick = (buttonName: string) => {
    let historydetails = getAllItems();
    const grouped = groupByDate(historydetails);
    setGroupedData(grouped);
    setActiveRecommendation(buttonName);
    setSidebarOpen(true);
  };
  let haiBuddyQues: string[] = [
    "What is HAI Buddy?",
    "Give a summary about H&M in two lines",
    "What is the relationship of TCS with H&M in two lines?",
    "Who is the current CEO of H&M?",
    "Type your own question below",
  ];
  const businessImpactQues: string[] = [
    "What are the current ongoing incidents?",
    "What is the business impact of current incidents?",
    "What are the incident numbers where US Store are impacted?",
    "Type your own question below",
  ];
  const storeManagerQues: string[] = [
    "Does start of the day report received for all stores?",
    "Which store has more visitors count yesterday?",
    "Which india store has less visitors day before yesterday ?",
    "Which US store in has more visitors yesterday then day before yesterday?",
    "Type your own question below",
  ];
  const vertexQues: string[] = [
    "Why Vertex?",
    "What is a VST?",
    "Can you share vendor contact information for Vertex?",
    "Total Incidents received for Vertex in last year?",
    "Current status of vertex application?",
    "Type your own question below",
  ];
  const storeNetworkQues: string[] = [
    "Can you share health status of SE0034 ?",
    "Can you share WAN status of SE0145 ?",
    "How many access points are connected in SE0145 ?",
    "Does store  SE0152 has any routers down ?",
    "Type your own question below",
  ];
  const salesIntelligenceQues: string[] = [
    "Which Sweden store did more sales ?",
    "Which is the most preferred size for adults in US ?",
    "What color product for kids is the best-selling in Sweden ?",
  ];
  const haiBuddy: MenuProps["items"] = [
    {
      key: "1",
      className: `${isDarkTheme ? "darkMenuProps" : ""}`,
      label: (
        <>
          {haiBuddyQues?.map((item, index) => (
            <div
              className={`${styles?.singleQue} ${"d-flex align-items-center"} ${
                isDarkTheme ? "darkSingleQue" : ""
              }`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(item, "HAI Buddy", "induction-index")
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      ),
    },
  ];
  const busiImpact: MenuProps["items"] = [
    {
      key: "1",
      className: `${isDarkTheme ? "darkMenuProps" : ""}`,
      label: (
        <>
          {businessImpactQues?.map((item, index) => (
            <div
              className={`${styles?.singleQue} ${"d-flex align-items-center"} ${
                isDarkTheme ? "darkSingleQue" : ""
              }`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(
                  item,
                  "Business Impact",
                  "p1-incident-data-index"
                )
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      ),
    },
  ];
  const strMng: MenuProps["items"] = [
    {
      key: "1",
      className: `${isDarkTheme ? "darkMenuProps" : ""}`,
      label: (
        <>
          {storeManagerQues?.map((item, index) => (
            <div
              className={`${styles?.singleQue} ${"d-flex align-items-center"} ${
                isDarkTheme ? "darkSingleQue" : ""
              }`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(item, "Store Manager", "store-index")
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      ),
    },
  ];
  const vertex: MenuProps["items"] = [
    {
      key: "1",
      className: `${isDarkTheme ? "darkMenuProps" : ""}`,
      label: (
        <>
          {vertexQues?.map((item, index) => (
            <div
              className={`${styles?.singleQue} ${"d-flex align-items-center"} ${
                isDarkTheme ? "darkSingleQue" : ""
              }`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(item, "Vertex", "vertex-index")
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      ),
    },
  ];
  const storenetwork: MenuProps["items"] = [
    {
      key: "1",
      className: `${isDarkTheme ? "darkMenuProps" : ""}`,
      label: (
        <>
          {storeNetworkQues?.map((item, index) => (
            <div
              className={`${styles?.singleQue} ${"d-flex align-items-center"} ${
                isDarkTheme ? "darkSingleQue" : ""
              }`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(item, "Store Network", "instorecare-index")
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      ),
    },
  ];
  const salesintelligent: MenuProps["items"] = [
    {
      key: "1",
      className: `${isDarkTheme ? "darkMenuProps" : ""}`,
      label: (
        <>
          {salesIntelligenceQues.length > 0 &&
            salesIntelligenceQues?.map((item, index) => (
              <div
                className={`${
                  styles?.singleQue
                } ${"d-flex align-items-center"} ${
                  isDarkTheme ? "darkSingleQue" : ""
                }`}
                key={index}
                onClick={() =>
                  onSuggestionQNClick(
                    item,
                    "Sales Intelligent",
                    "storesalesdata-index"
                  )
                }
              >
                {item}
                {item === "Type your own question below" && (
                  <span className="spriteUrl belowIcon"></span>
                )}
              </div>
            ))}
        </>
      ),
    },
  ];
  const handleClick = () => {
    if (!isLoading) {
      clearChat();
    }
  };

  return (
    <div
      className={popoverVisible ? styles?.mainPopEffect : styles?.mainContainer}
    >
      <AppHeader
        userDetails={GetUserName()}
        toggleClick={toggleTheme}
        showBamHeader={false}
      />
      <div className={`app ${isDarkTheme ? "dark" : "light"}`}>
        {GetRoles().includes("User.User") ||
        GetRoles().includes("Users") ||
        GetRoles().includes("BusinessImpact.Users") ? (
          <>
            <div>
              {selectedIndexName && lastQuestionRef?.current ? (
                <SelectedIndexComp
                  selectedIndexName={selectedIndexName}
                  item={
                    selectedIndexName === "HAI Buddy"
                      ? haiBuddy
                      : selectedIndexName === "Business Impact"
                      ? busiImpact
                      : selectedIndexName === "Vertex"
                      ? vertex
                      : selectedIndexName === "Store Manager"
                      ? strMng
                      : selectedIndexName === "Store Network"
                      ? storenetwork
                      : selectedIndexName === "Sales Intelligent"
                      ? salesintelligent
                      : []
                  }
                  onSampleQNClick={onSampleQNClick}
                  isLoading={isLoading}
                />
              ) : null}
              <CRow className="mx-0">
                <CCol
                  md={isSidebarOpen ? 9 : 12}
                  className={`${styles?.container + " " + "px-0"} ${
                    !lastQuestionRef?.current ? styles.chatBackground : ""
                  }`}
                >
                  {showAuthMessage ? (
                    <Stack className={styles?.chatEmptyState}>
                      <ErrorCircleRegular
                        className={styles?.chatIcon}
                        style={{ color: "crimson" }}
                      />
                      <h1 className={styles?.chatEmptyStateTitle}>
                        Authentication Not Configured
                      </h1>
                      <h2 className={styles?.chatEmptyStateSubtitle}>
                        This app does not have authentication configured. Please
                        add an identity provider.
                      </h2>
                      <h2 className={styles?.chatEmptyStateSubtitle}>
                        Go to your app in the
                        <a href="https://portal.azure.com/" target="_blank">
                          {" "}
                          Azure Portal{" "}
                        </a>
                        and follow
                        <a
                          href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization"
                          target="_blank"
                        >
                          {" "}
                          these instructions
                        </a>
                        .
                      </h2>
                    </Stack>
                  ) : (
                    <Stack horizontal className={styles?.chatRoot}>
                      <div
                        className={
                          styles?.chatContainer + " " + styles?.chatCnt
                        }
                      >
                        {!lastQuestionRef?.current ? (
                          <>
                            {!selectedIndexName ? (
                              <div
                                className={
                                  selectedIndexName === ""
                                    ? styles?.ServiceText
                                    : styles?.selectedIndSerTxt
                                }
                              >
                                <span
                                  className={`${styles.helpmeicon} helpmeicon`}
                                ></span>
                                <div
                                  className={
                                    isMobile
                                      ? styles?.mobileHelpmsg + " mobileHelpmsg"
                                      : styles?.helpmessage + " helpmessage"
                                  }
                                >
                                  How may I be of service to you today?
                                </div>
                              </div>
                            ) : (
                              <SelectedIndexComp
                                selectedIndexName={selectedIndexName}
                                item={
                                  selectedIndexName === "HAI Buddy"
                                    ? haiBuddy
                                    : selectedIndexName === "Business Impact"
                                    ? busiImpact
                                    : selectedIndexName === "Vertex"
                                    ? vertex
                                    : selectedIndexName === "Store Manager"
                                    ? strMng
                                    : selectedIndexName === "Store Network"
                                    ? storenetwork
                                    : selectedIndexName === "Sales Intelligent"
                                    ? salesintelligent
                                    : []
                                }
                                onSampleQNClick={onSampleQNClick}
                                isLoading={isLoading}
                              />
                            )}
                            <Stack
                              className={
                                isMobile
                                  ? styles?.mobileChatEmptyState
                                  : styles?.chatEmptyState
                              }
                              verticalAlign="center"
                            >
                              <div
                                className={
                                  isMobile
                                    ? styles?.mobileSampQueCnt
                                    : styles?.gridcontainer
                                }
                              >
                                {selectedIndex != "storesalesdata-index" &&
                                  SampleQuestion?.map((item, index) => (
                                    <>
                                      {item?.title !== "Sales Intelligent" ? (
                                        <Dropdown
                                          className={`${
                                            isMobile
                                              ? styles?.mobileSampQueDropDown
                                              : "SamQueDropdown"
                                          } ${
                                            isDarkTheme
                                              ? "DarkSamQueDropdown"
                                              : ""
                                          }`}
                                          menu={{
                                            items:
                                              item?.title === "HAI Buddy"
                                                ? haiBuddy
                                                : item?.title ===
                                                  "Business Impact (P1/Major Incident)"
                                                ? busiImpact
                                                : item?.title ===
                                                  "Store Manager"
                                                ? strMng
                                                : item?.title ===
                                                  "Operational AI Companion (Vertex)"
                                                ? vertex
                                                : item?.title ===
                                                  "Store Network"
                                                ? storenetwork
                                                : item?.title ===
                                                  "Sales Intelligent"
                                                ? salesintelligent
                                                : [],
                                          }}
                                          placement={
                                            item?.title === "HAI Buddy" ||
                                            item?.title ===
                                              "Business Impact (P1/Major Incident)"
                                              ? "bottom"
                                              : "top"
                                          }
                                          arrow
                                        >
                                          <div key={index} className="dropdown">
                                            <div
                                              key={index}
                                              className={`${
                                                styles?.griditem
                                              } ${"dropbtn"} ${
                                                item?.title === selectedSystem
                                                  ? "activeSystem"
                                                  : ""
                                              } ${
                                                isMobile
                                                  ? mobstyles?.mobGriditem
                                                  : ""
                                              } `}
                                              onClick={() =>
                                                onSampleQNClick(item)
                                              }
                                            >
                                              <h6
                                                className={`${
                                                  styles?.sample_question_title +
                                                  " sample_question_title"
                                                } ${
                                                  isMobile
                                                    ? mobstyles?.sampQueTitle +
                                                      " sampQueTitle"
                                                    : ""
                                                }`}
                                              >
                                                {item?.title}
                                              </h6>

                                              {/* {isMobile && (
                                        <MobileDropdown
                                          data={item.suggestedQuestions}
                                          onClickFunction={onSuggestionQNClick}
                                          indexName={
                                            item?.indexName
                                              ? item?.indexName
                                              : item?.title
                                          }
                                          index={item.index}
                                        />
                                      )} */}
                                              <div
                                                className={
                                                  isMobile
                                                    ? mobstyles?.sampleQueDes +
                                                      " sampleQueDes"
                                                    : styles?.sample_question_description +
                                                      " sample_question_description"
                                                }
                                              >
                                                {item?.description}
                                              </div>
                                            </div>
                                          </div>
                                        </Dropdown>
                                      ) : (
                                        <div key={index} className="dropdown">
                                          <div
                                            key={index}
                                            className={`${
                                              styles?.griditem
                                            } ${"dropbtn"} ${
                                              item?.title === selectedSystem
                                                ? "activeSystem"
                                                : ""
                                            } ${
                                              isMobile
                                                ? mobstyles?.mobGriditem
                                                : ""
                                            } `}
                                            onClick={() =>
                                              onSampleQNClick(item)
                                            }
                                          >
                                            <h6
                                              className={`${
                                                styles?.sample_question_title +
                                                " sample_question_title"
                                              } ${
                                                isMobile
                                                  ? mobstyles?.sampQueTitle +
                                                    " sampQueTitle"
                                                  : ""
                                              }`}
                                            >
                                              {item?.title}
                                            </h6>

                                            {/* {isMobile && (
                                        <MobileDropdown
                                          data={item.suggestedQuestions}
                                          onClickFunction={onSuggestionQNClick}
                                          indexName={
                                            item?.indexName
                                              ? item?.indexName
                                              : item?.title
                                          }
                                          index={item.index}
                                        />
                                      )} */}
                                            <div
                                              className={
                                                isMobile
                                                  ? mobstyles?.sampleQueDes
                                                  : styles?.sample_question_description +
                                                    " sample_question_description"
                                              }
                                            >
                                              {item?.description}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ))}
                              </div>
                              {/* {selectedIndexName ? (
                              <SelectedIndexComp
                                selectedIndexName={selectedIndexName}
                                item={
                                  selectedIndexName === "HAI Buddy"
                                    ? haiBuddy
                                    : selectedIndexName === "Business Impact"
                                    ? busiImpact
                                    : selectedIndexName === "Vertex"
                                    ? vertex
                                    : selectedIndexName === "Store Manager"
                                    ? strMng
                                    : []
                                }
                                onSampleQNClick={onSampleQNClick}
                              />
                            ) : null} */}
                            </Stack>
                          </>
                        ) : (
                          <div
                            className={
                              styles?.chatMessageStream + " " + styles?.chatBox
                            }
                            style={{
                              marginBottom: isLoading ? "0px" : "0px",
                              padding: "0px 10%",
                            }}
                          >
                            {answers?.map((answer, index) => (
                              <>
                                {answer?.role === "user" ? (
                                  <>
                                    <CRow className="mx-0 mb-3">
                                      <CCol className="px-0"></CCol>
                                      <CCol
                                        md={11}
                                        sm={11}
                                        className={`px-0 ${styles.userMessage}`}
                                      >
                                        <div>
                                          <span
                                            className={`${styles.chatMessageUserMessage} chatMessageUserMessage`}
                                          >
                                            {
                                              answer.content.split(
                                                "GrammerlyContent"
                                              )[0]
                                            }
                                          </span>
                                        </div>
                                      </CCol>
                                      <CCol
                                        sm={1}
                                        className={`px-0 ${styles.chatUserNameIcon}`}
                                      >
                                        <div
                                          className="chat-UserName"
                                          title={userDetails?.[0]?.userName}
                                        >
                                          {userDetails.length > 0 &&
                                            getInitials(
                                              userDetails?.[0]?.userName
                                            )}
                                        </div>
                                      </CCol>
                                    </CRow>
                                  </>
                                ) : answer?.role === "assistant" ? (
                                  <Answer
                                    answer={{
                                      answer: answer?.content,
                                      citations: parseCitationFromMessage(
                                        answers[index - 1]
                                      ),
                                    }}
                                    currentquestion={lastQuestionRef?.current}
                                    onCitationClicked={(c) => onShowCitation(c)}
                                    selectedindex={selectedIndexName}
                                    answerindex={index}
                                  />
                                ) : answer?.role === "systemQuestion" ? (
                                  <SystemAnswer
                                    answer={{
                                      answer: answer?.content,
                                      citations: parseCitationFromMessage(
                                        answers[index - 1]
                                      ),
                                    }}
                                    currentquestion={lastQuestionRef?.current}
                                    onCitationClicked={(c) => onShowCitation(c)}
                                    onYesClicked={() => {
                                      if (answer?.callback) {
                                        answer?.callback(
                                          answers,
                                          answer?.email
                                        );
                                      }
                                    }}
                                    onNoClicked={() => {
                                      if (answer?.noAnsCallback) {
                                        answer?.noAnsCallback(
                                          answers,
                                          answer?.email
                                        );
                                      }
                                    }}
                                    email={answer?.email ? answer?.email : ""}
                                  />
                                ) : answer?.role === "systemAnswer" ? (
                                  <SystemResponse
                                    answer={{
                                      answer: answer?.content,
                                      citations: parseCitationFromMessage(
                                        answers[index - 1]
                                      ),
                                    }}
                                    currentquestion={lastQuestionRef?.current}
                                    onCitationClicked={(c) => onShowCitation(c)}
                                    onYesClicked={() => {
                                      if (answer?.callback) {
                                        answer?.callback();
                                      }
                                    }}
                                    onNoClicked={() => {
                                      if (answer?.noAnsCallback) {
                                        answer?.noAnsCallback(
                                          answers,
                                          answer?.email
                                        );
                                      }
                                    }}
                                    email={answer?.email ? answer?.email : ""}
                                  />
                                ) : null}
                              </>
                            ))}

                            {showLoadingMessage && (
                              <>
                                <div className={styles?.chatMessageUser}>
                                  <div
                                    className={`${styles.chatMessageUserMessage} chatMessageUserMessage`}
                                  >
                                    {lastQuestionRef?.current}
                                  </div>
                                </div>

                                <Answer
                                  answer={{
                                    answer: "Generating answer...",
                                    citations: [],
                                  }}
                                  currentquestion={""}
                                  onCitationClicked={() => null}
                                  selectedindex={selectedIndexName}
                                />
                              </>
                            )}

                            <div ref={chatMessageStreamEnd} />
                          </div>
                        )}
                        <div
                          className={
                            isSidebarOpen
                              ? styles?.chatIpHalf
                              : styles?.chatIpWhole
                          }
                        >
                          <Stack
                            horizontal
                            className={
                              lastQuestionRef?.current
                                ? styles?.activechatPage
                                : styles?.chatInput
                            }
                            style={{
                              padding: !isMobile ? "0 10% 0 15%" : "0 2%",
                            }}
                          >
                            {isLoading && (
                              <Stack
                                horizontal
                                className={styles?.stopGeneratingContainer}
                                role="button"
                                aria-label="Stop generating"
                                tabIndex={0}
                                onClick={stopGenerating}
                                onKeyDown={(e) =>
                                  e?.key === "Enter" || e?.key === " "
                                    ? stopGenerating()
                                    : null
                                }
                              >
                                <SquareRegular
                                  className={styles?.stopGeneratingIcon}
                                  aria-hidden="true"
                                />
                                <span
                                  className={`${styles?.stopGeneratingText} stopGeneratingText`}
                                  aria-hidden="true"
                                >
                                  Stop generating
                                </span>
                              </Stack>
                            )}
                            <div
                              className={`${
                                lastQuestionRef?.current
                                  ? styles?.chatInputContainer
                                  : styles?.chatcontainer
                              } `}
                            >
                              {(lastQuestionRef?.current ||
                                selectedIndex == "storesalesdata-index") && (
                                <>
                                  <div className={styles.promptMainDiv}>
                                    {selectedIndex ==
                                      "storesalesdata-index" && (
                                      <div className="PrompQuestions">
                                        <>
                                          {salesIntelligentPromtQuest.map(
                                            (item) => (
                                              <CButton
                                                className={`${"promtBtn"} ${"InlineDisplayProperty"}`}
                                                onClick={() =>
                                                  makeApiRequest(item)
                                                }
                                              >
                                                {item}
                                              </CButton>
                                            )
                                          )}
                                          <span
                                            className={
                                              styles?.selectedIndexIcon
                                            }
                                          >
                                            <Dropdown
                                              className={"SamQueDropdown"}
                                              menu={{
                                                items: salesintelligent,
                                              }}
                                            >
                                              <div
                                                className="dropdown"
                                                style={{
                                                  pointerEvents: isLoading
                                                    ? "none"
                                                    : "auto",
                                                }}
                                              >
                                                {/* <div
                className={`${styles?.griditem} ${"dropbtn"}`}
                // onClick={() => onSampleQNClick(item)}
              ></div> */}
                                                <span
                                                  className={`${styles.gramerlyicon} gramerlyicon`}
                                                  onClick={() => {
                                                    setSampleQnPopVisible(true);
                                                  }}
                                                ></span>
                                              </div>
                                            </Dropdown>
                                          </span>
                                        </>
                                      </div>
                                    )}
                                    <div
                                      className={`${styles?.answerTextButtonDiv} ${styles?.newConvoProperty}`}
                                    >
                                      <TooltipHost
                                        content={
                                          isLoading
                                            ? "Please Click Stop Generating Answer"
                                            : ""
                                        }
                                      >
                                        <DefaultButton
                                          className={`${styles?.AnswerTexButton} AnswerTexButton`}
                                          // Only call handleClick if isLoading is false
                                          onClick={handleClick}
                                          // Disable the button if isLoading is true
                                          disabled={isLoading}
                                        >
                                          <span
                                            className={`${styles?.spriteUrl} ${styles?.msgIcon} spriteUrl msgIcon`}
                                          ></span>
                                          <span className="defaultFontFamily">
                                            New Conversation / Home
                                          </span>
                                        </DefaultButton>
                                      </TooltipHost>

                                      {!isMobile && (
                                        <CButton
                                          className={`${
                                            activeRecommendation ===
                                              "History" && isSidebarOpen
                                              ? "activeRecommendBtn"
                                              : "recommendBtn"
                                          } ${"InlineDisplayProperty"}`}
                                          onClick={() =>
                                            handleRecommendationClick("History")
                                          }
                                        >
                                          <span
                                            className={` ${styles?.spriteUrl} ${
                                              "spriteUrl HistoryIcon" +
                                              " " +
                                              "InlineDisplayProperty"
                                            }`}
                                          ></span>
                                          <span
                                            className={`${styles?.btnName} btnName`}
                                          >
                                            {"History"}
                                          </span>
                                        </CButton>
                                      )}
                                      <div
                                        className="GramerlyPopDiv"
                                        ref={popoverRef}
                                        style={{
                                          pointerEvents: isLoading
                                            ? "none"
                                            : "auto",
                                        }}
                                      >
                                        <span
                                          className={`${styles.gramerlyicon} gramerlyicon`}
                                          onClick={() => {
                                            setGrammarlyPopVisible(true);
                                          }}
                                        ></span>
                                        <CPopover
                                          content={
                                            <GramerlyContent
                                              repostQuestion={repostQuestion}
                                            />
                                          }
                                          placement="top"
                                          className={`${
                                            styles?.gramerlyPopover
                                          } ${
                                            isDarkTheme
                                              ? "darkgramerlyPopover"
                                              : ""
                                          }`}
                                          visible={grammarPopVisible}
                                          // isOpen={isOpen}
                                          // onClose={() => setIsOpen(false)}
                                          onHide={() => {
                                            setGrammarlyPopVisible(false);
                                          }}
                                        >
                                          <span></span>
                                        </CPopover>
                                      </div>

                                      {!isMute ? (
                                        <Speaker228Filled
                                          className={`${
                                            styles?.Speaker + " " + "ms-2"
                                          } Speaker228Filled`}
                                          onClick={() => stopSpeaking()}
                                        ></Speaker228Filled>
                                      ) : (
                                        <SpeakerMute28Regular
                                          onClick={() => setIsMute(false)}
                                          className={`${
                                            styles?.Speaker + " " + "ms-2"
                                          } SpeakerMute28Regular`}
                                        ></SpeakerMute28Regular>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className={styles?.bottomDiv}>
                                <QuestionInput
                                  clearOnSend
                                  placeholder="Message AI Companion..."
                                  disabled={isLoading}
                                  onSend={(question) =>
                                    makeApiRequest(question)
                                  }
                                  voicedisabled={
                                    (isSpeechStarted && !isMute) || isLoading
                                  }
                                />
                                <div
                                  className={`${styles?.disclaimer} disclaimer`}
                                >
                                  <span>
                                    <b>
                                      Despite our accuracy efforts, users should
                                      independently verify information and use
                                      discretion in making informed decisions.
                                    </b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Stack>
                        </div>
                      </div>
                      {answers.length > 0 &&
                        isCitationPanelOpen &&
                        !isMobile &&
                        activeCitation && (
                          <Stack.Item className={styles?.citationPanel}>
                            <Stack
                              horizontal
                              className={styles?.citationPanelHeaderContainer}
                              horizontalAlign="space-between"
                              verticalAlign="center"
                            >
                              <span className={styles?.citationPanelHeader}>
                                Citations
                              </span>
                              <DismissRegular
                                className={styles?.citationPanelDismiss}
                                onClick={() => setIsCitationPanelOpen(false)}
                              />
                            </Stack>
                            <h5 className={styles?.citationPanelTitle}>
                              {activeCitation[2]}
                            </h5>
                            <ReactMarkdown
                              linkTarget="_blank"
                              className={styles?.citationPanelContent}
                              children={activeCitation[0]}
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                            />
                          </Stack.Item>
                        )}
                      {isMobile && answers.length > 0 && activeCitation && (
                        <CModal
                          visible={isCitationPanelOpen}
                          onClose={() => setIsCitationPanelOpen(false)}
                        >
                          <Stack.Item className={` ${mobstyles?.mobCitation}`}>
                            <Stack
                              horizontal
                              className={`${styles?.citationPanelHeaderContainer} ${mobstyles?.mobhdrcnt}`}
                              horizontalAlign="space-between"
                              verticalAlign="center"
                            >
                              <span className={styles?.citationPanelHeader}>
                                Citations
                              </span>
                              <DismissRegular
                                className={styles?.citationPanelDismiss}
                                onClick={() => setIsCitationPanelOpen(false)}
                              />
                            </Stack>
                            <h5 className={styles?.citationPanelTitle}>
                              {activeCitation[2]}
                            </h5>
                            <ReactMarkdown
                              linkTarget="_blank"
                              className={`${styles?.citationPanelContent} ${
                                mobstyles?.panelContent
                              } ${"citationPanelContent"}`}
                              children={activeCitation[0]}
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                            />
                          </Stack.Item>
                        </CModal>
                      )}
                    </Stack>
                  )}
                  <div>
                    <Modal
                      titleAriaId="modalTitle"
                      isOpen={isModalOpen}
                      onDismiss={hideModal}
                      isBlocking={false}
                      styles={{
                        main: { width: 800 },
                        scrollableContent: { padding: 20 },
                      }}
                    >
                      <div>
                        <h2 id="modalTitle">Send Email</h2>
                      </div>

                      <div>
                        <Stack tokens={{ childrenGap: 10 }}>
                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <TextField
                              label="SMTP Provider"
                              required
                              defaultValue={smtpProvider}
                              onChange={(e, newValue) =>
                                setSmtpProvider(newValue || "")
                              }
                            />
                            <TextField
                              label="Sender EmailID"
                              required
                              defaultValue={senderEmailID}
                              onChange={(e, newValue) =>
                                setSenderEmailID(newValue || "")
                              }
                            />
                            <TextField
                              type="password"
                              label="App Password"
                              required
                              defaultValue={appPassword}
                              onChange={(e, newValue) =>
                                setAppPassword(newValue || "")
                              }
                            />
                            <TextField
                              label="Receiver EmailID"
                              required
                              defaultValue={receiverEmailID}
                              onChange={(e, newValue) =>
                                setReceiverEmailID(newValue || "")
                              }
                            />
                          </Stack>
                          <TextField
                            label="Subject"
                            required
                            defaultValue={subject}
                            onChange={(e, newValue) =>
                              setSubject(newValue || "")
                            }
                          />
                          <TextField
                            label="Content"
                            required
                            multiline
                            rows={10}
                            defaultValue={content}
                            onChange={(e, newValue) =>
                              setContent(newValue || "")
                            }
                          />
                        </Stack>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "20px",
                        }}
                      >
                        <PrimaryButton
                          onClick={onSend}
                          style={{ marginRight: "10px" }}
                        >
                          Send
                        </PrimaryButton>
                        <DefaultButton onClick={hideModal}>
                          Cancel
                        </DefaultButton>
                      </div>
                    </Modal>
                  </div>
                  <div>
                    <Modal
                      titleAriaId="psmodalTitle"
                      isOpen={isPsModalOpen}
                      onDismiss={hidePsModal}
                      isBlocking={false}
                      styles={{
                        main: { width: 800 },
                        scrollableContent: { padding: 20 },
                      }}
                    >
                      <div>
                        <h2 id="modalTitle">Execute PowershellScript</h2>
                      </div>

                      <div>
                        <Stack tokens={{ childrenGap: 10 }}>
                          <TextField
                            label="Script"
                            required
                            multiline
                            rows={10}
                            defaultValue={psscript}
                            onChange={(e, newValue) =>
                              setPsScript(newValue || "")
                            }
                          />
                        </Stack>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "20px",
                        }}
                      >
                        <PrimaryButton
                          onClick={onExecute}
                          style={{ marginRight: "10px" }}
                        >
                          Execute
                        </PrimaryButton>
                        <DefaultButton onClick={hidePsModal}>
                          Cancel
                        </DefaultButton>
                      </div>
                    </Modal>
                  </div>
                </CCol>
                {isSidebarOpen && !isMobile && (
                  <CCol md={3} className="px-0">
                    <RecommendationTab
                      historydata={groupedData}
                      historyOnlickfunction={setHistoryToCurrent}
                    />
                  </CCol>
                )}
              </CRow>
            </div>
          </>
        ) : (
          <div className={styles.verticalcenter}>
            <div
              role="alert"
              className="text-center w-100"
              data-testid="errorFallback"
            >
              <div className="mb-1">Access Denied</div>
              <pre>You don't have access to this application</pre>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
interface SampleQuestionsProps {
  item: MenuProps["items"];
  onSampleQNClick: (item: {
    title: string;
    indexName?: string;
    index: string;
    description: string;
  }) => void;
}
export const SampleQuestionsDropDown: React.FC<SampleQuestionsProps> = ({
  item,
  onSampleQNClick,
}) => {
  const { sampleQnPopVisible, setSampleQnPopVisible } = useAppContext();
  // Component implementation
  return (
    <div className={styles?.dropdown}>
      <div className={styles.ModalCloseBtn}>
        <span
          onClick={() => {
            setSampleQnPopVisible(false);
          }}
          className={`${styles.spriteUrl} ${styles.modalCloseIcon}`}
        ></span>
      </div>
      <Dropdown
        className={"SamQueDropdown"}
        menu={{
          items: item,
        }}
      >
        <div className="dropdown">
          <div
            className={`${styles?.griditem + " griditem"} ${"dropbtn"}`}
            // onClick={() => onSampleQNClick(item)}
          ></div>
        </div>
      </Dropdown>
    </div>
  );
};
interface SelectedIndexCompProps {
  selectedIndexName: string;
  item?: MenuProps["items"];
  onSampleQNClick: (item: {
    title: string;
    indexName?: string;
    index: string;
    description: string;
  }) => void;
  isLoading?: boolean;
}

export const SelectedIndexComp: React.FC<SelectedIndexCompProps> = ({
  selectedIndexName,
  item,
  onSampleQNClick,
  isLoading,
}) => {
  const { sampleQnPopVisible, setSampleQnPopVisible, isDarkTheme } =
    useAppContext();
  return (
    <>
      {item && (
        <div className={styles?.selectedIndexMainDiv}>
          <div className={`${styles?.selectedIndex_Row} selectedIndex_Row`}>
            <span className={styles?.selected_index}>{selectedIndexName}</span>

            {selectedIndexName != "Sales Intelligent" && (
              <span className={styles?.selectedIndexIcon}>
                <Dropdown
                  className={`${"SamQueDropdown"} ${
                    isDarkTheme ? "darkSamQueDropdown" : ""
                  }`}
                  menu={{
                    items: item,
                  }}
                >
                  <div
                    className={`${"dropdown"} ${
                      isDarkTheme ? "darkDropdown" : ""
                    }`}
                    style={{ pointerEvents: isLoading ? "none" : "auto" }}
                  >
                    {/* <div
                className={`${styles?.griditem} ${"dropbtn"}`}
                // onClick={() => onSampleQNClick(item)}
              ></div> */}
                    <span
                      className={`${styles.gramerlyicon} gramerlyicon`}
                      onClick={() => {
                        setSampleQnPopVisible(true);
                      }}
                    ></span>
                  </div>
                </Dropdown>
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};
