import { CFormCheck } from "@coreui/react";
import React from "react";
import styles from "./GramerlyContent.module.css";
import { useAppContext } from "../../components/ContextAndProvider/Context";
interface Props {
  repostQuestion: (repostQuestion: string) => void;
}

const GramerlyContent: React.FC<Props> = ({ repostQuestion }) => {
  const {
    gramerlyContent,
    setGramerlyContent,
    grammarPopVisible,
    setGrammarlyPopVisible,
    activeGrammar,
    setActiveGrammar,
    isDarkTheme,
  } = useAppContext();

  const handleCheckboxChange = (
    category: "Rephrase" | "AdjustTone",
    label: string
  ) => {
    setActiveGrammar(activeGrammar === label ? null : label);
    repostQuestion(activeGrammar === label ? "" : label);
    // const updatedContent = {
    //   ...gramerlyContent,
    //   [category]: gramerlyContent[category].map((item) =>
    //     item.label === label ? { ...item, value: !item.value } : item
    //   ),
    // };
    // console.log(updatedContent, "updatedContent");

    // setGramerlyContent(updatedContent);
  };

  return (
    <div
      className={`${styles.grammerlycheckbox} ${
        isDarkTheme ? "darkGrammerlycheckbox" : ""
      }`}
    >
      <div className={styles.ModalCloseBtn}>
        <span
          onClick={() => {
            setGrammarlyPopVisible(false);
          }}
          className={`${styles.spriteUrl} ${styles.modalCloseIcon}`}
        ></span>
      </div>
      <h6>Rephrase</h6>
      {gramerlyContent.Rephrase?.map((value, i) => (
        <CFormCheck
          key={value.label + "" + i}
          type="radio"
          id={value.label}
          label={value.label}
          onClick={() => handleCheckboxChange("Rephrase", value.label)}
          checked={activeGrammar === value.label}
        />
      ))}
      <h6>Adjust tone</h6>
      {gramerlyContent.AdjustTone?.map((value, i) => (
        <CFormCheck
          type="radio"
          key={value.label + "" + i}
          id={value.label}
          label={value.label}
          onClick={() => handleCheckboxChange("AdjustTone", value.label)}
          checked={activeGrammar === value.label}
        />
      ))}
    </div>
  );
};

export default GramerlyContent;
