import React, { useState } from "react";
import Plot from "react-plotly.js";
import { BarChartOutlined } from "@ant-design/icons";
import { TooltipHost } from "@fluentui/react";

interface Props {
  plotdata: any;
}

const PlotComponent = ({ plotdata }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const colorOptions = ["#838CCF"];

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colorOptions.length);
    return colorOptions[randomIndex];
  };

  const updatedPlotData = plotdata?.data?.map((data: any) => ({
    ...data,
    marker: {
      ...data.marker,
      color: getRandomColor(),
    },
    line: {
      ...data.line,
      color: getRandomColor(),
    },
  }));

  return (
    <div>
      {plotdata?.data && (
        <>
          <div
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={toggleVisibility}
          >
            <TooltipHost content={"View Charts"}>
              <BarChartOutlined />
              <span style={{ marginLeft: "8px" }}>
                {isVisible ? "▲" : "▼"} {/* Up or down arrow */}
              </span>
            </TooltipHost>
          </div>
          {isVisible && plotdata?.data && (
            <Plot data={updatedPlotData} layout={plotdata?.layout} />
          )}
        </>
      )}
    </div>
  );
};

export default PlotComponent;
