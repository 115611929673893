import React from "react";
import { CModalBody } from "@coreui/react";
import styles from "./Recommendation.module.css";

const VideoModal: React.FC = () => {
  return (
    <CModalBody className={styles?.modalBox}>
      <video
        className={styles?.rawVideo}
        src="https://saaicompanion.blob.core.windows.net/videos-container/What's next for AI and Google Search _ Google I_O 2023.mp4"
        controls
        autoPlay
      />
    </CModalBody>
  );
};

export default VideoModal;
