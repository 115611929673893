import styles from "./Home.module.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { GetRoles } from "../../services/AuthUser";
import { useAppContext } from "../../components/ContextAndProvider/Context";
const Store = () => {
  const handleLogin = (loginType: "popup" | "redirect"): void => {};
  const navigate = useNavigate();
  const {isDarkTheme} = useAppContext()
  console.log("DarkTheme",isDarkTheme)

  const handleButtonClick = () => {
    // Redirect to /chat
    if (GetRoles().includes("SalesICompanion.Users")) {
      navigate("/salesChat");
    } else {
      navigate("/chat");
    }
  };
  return (
    <div className={styles?.container}>
      <CContainer data-testid="notLogged">
        <CForm>
          <CRow>
            <CCol xs={12}>
              <h1 className={styles?.home_title} style={{ color: isDarkTheme ? "#FFFFFF" : "#000000" }}>
                MAG-AI Companion
              </h1>
            </CCol>
          </CRow>
          <p className={styles?.home_description}>
            Empowering business teams with AI-driven insights and real-time solutions to identify <br></br> key customer engagement metrics and enhance overall performance.
          </p>
          <CRow>
            <CCol xs={12}>
              <CButton
                className={`${styles?.home_getstarted_btn} home_getstarted_btn`}
                onClick={handleButtonClick}
              >
                Get Started Now
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </CContainer>
    </div>
  );
};
export default Store;
